import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "quantity", "total" ]

  connect() {
    this.update()
  }

  update() {
    this.totalTarget.textContent = this.quantityTarget.value
  }
}
