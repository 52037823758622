import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'cardElement', 'cardErrors', 'form' ]

  connect() {
    var stripe = Stripe(this.data.get('key'))
    var elements = stripe.elements()
    var style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }

    var card = elements.create('card', {
      style: style
    })

    card.mount(this.cardElementTarget)

    let controller = this
    card.addEventListener('change', function (event) {
      var displayError = controller.cardErrorsTarget
      if (event.error) {
        displayError.textContent = event.error.message
      } else {
        displayError.textContent = ''
      }
    })

    var form = controller.formTarget
    form.addEventListener('submit', function (event) {
      event.preventDefault()

      stripe.createToken(card).then(function (result) {
        if (result.error) {
          var errorElement = controller.cardErrorsTarget
          errorElement.textContent = result.error.message
        } else {
          controller.stripeTokenHandler(result.token)
        }
      })
    })


  }

  stripeTokenHandler(token) {
    var form = this.formTarget
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'account[card_token]')
    hiddenInput.setAttribute('value', token.id)
    form.appendChild(hiddenInput)

    form.submit()
  }
}
