import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "price", "output" ]

  update(event) {
    this.index = this.priceTargets.indexOf(event.currentTarget)

    var total = this.priceTargets[this.index].getAttribute("data-shipping-price")

    this.outputTarget.textContent = `$${total}`
    this.outputTarget.setAttribute("data-payment-calculator-value", total)

    this.paymentCalculatorController.update()
  }

  get index() {
    return parseInt(this.data.get("index"))
  }

  set index(value) {
    this.data.set("index", value)
  }

  get paymentCalculatorController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "payment-calculator")
  }
}
