import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "div" ]

  connect() {
    this.change()
  }

  change() {
    if (this.checkboxTarget.checked) {
      this.divTarget.classList.add("hidden")
    } else {
      this.divTarget.classList.remove("hidden")
    }
  }

  submit() {
    this.divTarget.remove()
    return true
  }
}
