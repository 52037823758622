import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "states" ]

  change(event) {
    this.country = event.target.value
    this.load()
  }

  load() {
    fetch(`${this.baseUrl}/${this.country}`)
      .then(response => response.text())
      .then(data => this.setOptions(JSON.parse(data)))
  }

  setOptions(states) {
    this.clearOptions()

    states.forEach(state => {
      var option = document.createElement("option")
      option.text = state
      option.value = state

      this.statesTarget.add(option)
    })

    this.activate()
  }

  clearOptions() {
    var option = document.createElement("option")
    option.text = "Select"
    option.value = ""

    this.statesTarget.innerHTML = ""
    this.statesTarget.add(option)
  }

  activate() {
    this.statesTarget.disabled = false
  }

  get country() {
    return this.data.get("country")
  }

  set country(value) {
    this.data.set("country", value)
  }

  get baseUrl() {
    return this.data.get("baseUrl")
  }
}
