import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "item", "output" ]

  connect() {
    this.update()
  }

  update() {
    var total = this.itemTargets.reduce((a, b) => a + parseFloat(b.getAttribute("data-payment-calculator-value")), parseFloat(0))

    this.outputTarget.textContent =
      `$${total.toFixed(2)}`
  }
}
