import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tab", "panel", "overlay", "pill", "step" ]

    connect() {
      this.showTab()
    }

    change(event) {
      this.index = this.tabTargets.indexOf(event.currentTarget)
    }

    showTab() {
      this.tabTargets.forEach((tab, index) => {
        const panel = this.panelTargets[index]
        const overlay = this.overlayTargets[index]
        const pill = this.pillTargets[index]
        const step = this.stepTargets[index]
        tab.classList.toggle("border-b-4", index == this.index)
        tab.classList.toggle("border-blue-200", index == this.index)

        pill.classList.toggle("bg-blue-200", index == this.index)
        pill.classList.toggle("text-white", index == this.index)

        step.classList.toggle("text-blue-200", index == this.index)
        panel.classList.toggle("hidden", index != this.index)
        overlay.classList.toggle("hidden", index != this.index)
      })
    }

    get index() {
      return parseInt(this.data.get("index") || 0)
    }

    set index(value) {
      this.data.set("index", value)
      this.showTab()
    }
}
