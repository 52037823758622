import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "quantity", "name" ]

  connect() {
    this.update()
  }

  update() {
    const x = parseInt(this.quantityTarget.value) + parseInt(this.data.get("currentTagsCount"));

    switch (true) {
      case (x < 11):
        this.nameTarget.textContent = "Basic"
        break;
      case (x < 101):
        this.nameTarget.textContent = "Standard"
        break;
      default:
        this.nameTarget.textContent = "Professional"
        break;
    }
  }
}
